import { getDoc } from "@concentratio/docs";
import type { RouteSectionProps } from "@solidjs/router";
import "katex/dist/katex.min.css";
import { lazy } from "solid-js";
import "~/assets/css/hljs.css";

// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
const Docs = lazy(() => getDoc("en", "api") as any);

export default function Help(props: RouteSectionProps) {
  return (
    <main class="w-full flex flex-col items-center">
      <div class="max-w-4xl p-8 pt-0 prose prose-neutral dark:prose-invert">
        <Docs />
      </div>
    </main>
  );
}
